<template>
  <div class="faq">
    <ElementCard>
      <ElementCollapse class="faq__collapse">
        <ElementCollapseItem
          v-for="(item, key) in content"
          :key="key"
          :name="item.title"
        >
          <template slot="title">
            <h4 class="faq__title">
              {{ item.title }}
            </h4>
          </template>
          <div class="faq__description">
            <p
              class="faq__description-text"
              v-html="item.description"
            />
          </div>
          <div class="faq__attachments">
            <div
              v-for="(file, index) in item.attachments"
              :key="index"
              class="faq__attachments-images"
            >
              <div
                v-if="file.type === 'image'"
                class="faq__attachments-image"
              >
                <img
                  :src="file.url"
                  :alt="file.alt"
                >
              </div>
            </div>
          </div>
        </ElementCollapseItem>
      </ElementCollapse>
    </ElementCard>
  </div>
</template>

<script>
import faqData from './assets/faq.json'

export default {
  name: 'Faq',

  data () {
    return {
      content: faqData.content
    }
  }
}
</script>

<style lang="scss" scoped>
.faq {
  height: 100%;
}

.faq__container {
  height: 100%;
  padding: spacing(6) spacing(4);
  overflow-y: scroll;
}

.faq__container::-webkit-scrollbar {
  display: none;
}

.faq__container h2 {
  margin-bottom: spacing(2.5);
}

.faq--header {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin-bottom: spacing(2);
  padding: 0;
}

.faq__card {
  margin-bottom: spacing(4);
  padding: spacing(2);

  h1 {
    margin-bottom: spacing(1);
  }

  p {
    margin-bottom: spacing(2);
  }

  .faq__list-main {
    @include text-title-small;
  }

  .faq__list-sub {
    @include text-body;

    margin: spacing(1) 0;
    padding-left: spacing(2);
    list-style: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.faq__collapse {
  border-top: 0;
}

.faq__title {
  font-weight: $font-weight-medium;
  font-size: spacing(2);
}

.faq__description-text {
  font-size: spacing(1.8);
}

.faq__attachments-images {
  padding: spacing(2);
}

.faq__attachments-image {
  width: percentage(60);
  margin-left: percentage(20);
}

.faq__attachments-image img {
  width: percentage(100);
  padding: spacing(1);
}
</style>
