<template>
  <div class="faq">
    <div class="faq__container">
      <div class="faq--header">
        <h1>FAQ</h1>
      </div>
      <faq />
    </div>
  </div>
</template>

<script>
import faq from '@/components/faq'
import { mapActions } from 'vuex'

export default {
  name: 'FAQ',

  components: { faq },

  data () {
    return {
      isLoading: false,
      reset: false
    }
  },

  methods: {
    ...mapActions([]),

    /**
     *
     */
    restoreToDefault () {}
  }
}
</script>

<style lang="scss" scoped>
.faq {
  height: 100%;
}

.faq__container {
  height: 100%;
  padding: spacing(2);
  padding-top: spacing(3);
  overflow-y: scroll;
}

.faq__container::-webkit-scrollbar {
  display: none;
}

.faq__container h1 {
  margin-bottom: spacing(2.5);
}

.faq--header {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}

.faq__card {
  margin-bottom: spacing(4);
  padding: spacing(2);

  h1 {
    margin-bottom: spacing(1);
  }

  p {
    margin-bottom: spacing(2);
  }

  .faq__list-main {
    @include text-title-small;
  }

  .faq__list-sub {
    @include text-body;

    margin: spacing(1) 0;
    padding-left: spacing(2);
    list-style: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
